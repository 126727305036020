.breadcrumbs {
	background-color: #F8F7F2;
	padding: .5rem 1rem;
	margin: 0 0 1.5em;

	li {
		&:not(:first-child):before {
			content: '';
			display: inline-block;
			margin-right: .75rem;
			width: .5em;
			height: .5em;
			border-top: 1px solid #46210c;
			border-right: 1px solid #46210c;
			transform: rotate(45deg);
		}
	}

	a {
		&:focus,
		&:hover {
			text-decoration: none;
		}
	}
}