// 
// HOME
//   - Section
//       - Layout
//       - Header
//   - Floorguide
//       - Mobile link
//       - Section style
//   - News & Event
//       - Item
// 
// 

@mixin icon-arrow() {
	&:before {
		content: '';
		display: inline-block;
		margin-right: .25em;
		vertical-align: text-bottom;
		width: rem-calc(16);
		height: rem-calc(16);
		background-image: url("../img/common/icon/icon-arrow-circle.svg");
		background-repeat: no-repeat;
	}
}


//
// Section
//

// Layout
@mixin home-section-style() {
	padding-top: 30px;
	padding-bottom: 30px;
}

.p-home-section {
	@include home-section-style();
}

// Header
@mixin home-section-header() {
	@include clear-header-style();
	@include noto-sans();
	margin-top: 0;
	margin-bottom: 1em;
	color: $color-brown;
	font-size: rem-calc(20);
	font-weight: bold;
	text-align: center;

	> span {
		display: block;

		&.ja {
			&:before,
			&:after {
				content: '－';
			}
		}

		&.en {
			color: #E47F12;
			font-size: rem-calc(14);
			font-weight: normal;
		}
	}

	@include breakpoint(medium) {
		font-size: rem-calc(26);
	}
}

.p-home-section-header {
	@include home-section-header();
}


//
// フロアガイド
// Floorguide
//

// Mobile link
@mixin home-floorguide-sp-link() {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $color-beige;
	color: $color-brown;
	border-top: 1px solid #999999;
	border-bottom: 1px solid #999999;
	padding: 1em;
	font-size: rem-calc(19);
	font-weight: bold;
	text-decoration: none;
	transition: all .25s ease-out;

	// Arrow
	&:before {
		content: '';
		display: block;
		margin-right: .5rem;
		width: 0;
		height: 0;
		border-left: 13px solid currentColor;
		border-top: 8px solid transparent;
		border-bottom: 8px solid transparent;
	}

	&:focus,
	&:hover {
		color: $color-brown;
		background-color: darken($color-beige, 10%);
	}
}

.p-home-floorguide-sp-link {
	@include home-floorguide-sp-link();
}

// Section style
@mixin home-floorguide-section-style() {
	background-color: #F8F7F2;
	padding-left: 20px;
	padding-right: 20px;
}

.p-home-section--floorguide {
	@include home-floorguide-section-style();
}

// Information
@mixin home-floorguide-info() {
	border-bottom: 1px solid #8c6239;
	margin-bottom: 1rem;
	font-size: rem-calc(12);

	span {
		display: block;
	}

	&:last-of-type {
		border-bottom: 0;
		margin-bottom: 0;
	}
}

.p-home-floorguide-info {
	@include home-floorguide-info();
}


// 
// 
// 

.p-home-side-banner {
	margin-top: 20px;
	margin-bottom: 70px;

	> li {
		margin-bottom: 15px;
	}
}


//
// お知らせ＆イベント情報
// News & Event
//

// @see _news.scss


.p-home-section--news,
.p-home-section--shopnews {
	.p-news-item {
		&:nth-of-type(7),
		&:nth-of-type(8),
		&:nth-of-type(9),
		&:nth-of-type(10),
		&:nth-of-type(11),
		&:nth-of-type(12) {
			@include breakpoint(small down) {
				display: none;
			}
		}
	}
}


// 
// 求人情報
// Recruit
// 
.p-home-recruit-container {
	text-align: center;
}

.p-home-recruit {
	display: inline-flex;
	text-align: left;
	background-image: repeating-linear-gradient(135deg, #fde7c6 0, #fde7c6 6px, #facd89 6px, #facd89 12px);
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: rem-calc(40);

	> a {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		border: 2px solid #ff9900;
		text-decoration: none;
		color: $body-font-color;

		@include breakpoint(small down) {
			padding: 10px;
		}
	}

	.p-home-recruit__header-en {
		margin-right: 2em;

		@include breakpoint(small down) {
			margin-right: 10px;
		}

		p {
			margin: 0;
		}
	}

	.p-home-recruit__body {
		background-color: #FFF;
		flex: 1 1 auto;
		display: flex;
		align-items: center;
		padding: 1em;

		@include breakpoint(small down) {
			display: block;
		}

		h3 {
			white-space: nowrap;
			@include clear-header-style();
			color: #e47f12;
			font-size: rem-calc(24);
			margin: 0;
			margin-right: 1rem;

			@include breakpoint(small down) {
				font-size: rem-calc(18);
				margin-right: 0;
			}
		}

		p {
			font-size: rem-calc(14);
			margin: 0;
		}

		.p-home-recruit__more {
			white-space: nowrap;
			@include icon-arrow();
			color: $color-brown;
			font-weight: bold;
			margin-left: 1em;

			@include breakpoint(small down) {
				display: none;
			}
		}
	}

	.p-home-recruit__header-en {
	}
}


.p-home-news__more {
	max-width: 20em;
	margin-left: auto;
	margin-right: auto;
}

//
// ショップニュース
// Shop news
//

.p-home-section--shopnews {
	background-color: $color-beige;
	padding-top: 40px;
	margin-bottom: 70px;

	@include breakpoint(small down) {
		margin-bottom: 40px;
	}
}


//
// 特集
// Topico souvenir
//

// 毎日おいしい＆みんなにうれしい今日も出来立て召し上がれ♪
@mixin home-souvenir-header() {
	@include clear-header-style();
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100%;
	margin: 0;
	padding: 1em;
}

// Link item
@mixin home-souvenir-item() {
	height: 100%;
	margin: 0;
	padding: 1em;
	color: $body-font-color;
	font-size: rem-calc(12);
	text-decoration: none;
	transition: all .25s ease-out;

	&:focus,
	&:hover {
		color: $primary-color;
	}
}

// Link image
@mixin home-souvenir-item-image() {
	width: percentage(170px / 340px);

	img {
		@include image-expanded();
	}
}

// Link title
@mixin home-souvenir-item-title() {
	@include icon-arrow();
}

// Border
.p-home-souvenir {
	> .row {
		> .column,
		> .columns {

			> * {
				border-top: 1px solid #8C6239;
			}

			&:first-child {
				> * {
					@include breakpoint(small down) {
						border-top: 0;
					}
				}
			}

			&:last-child {
				> * {
					@include breakpoint(small down) {
						border-bottom: 1px solid #8C6239;
					}
				}
			}

			&:nth-last-of-type(1),
			&:nth-last-of-type(2),
			&:nth-last-of-type(3) {
				> * {
					@include breakpoint(medium) {
						border-bottom: 1px solid #8C6239;
					}
				}
			}
		}
	}
}

.p-home-souvenir {
	&__header {
		@include home-souvenir-header();
	}

	&-item {
		@include home-souvenir-item();

		&__image {
			@include home-souvenir-item-image();
		}

		&__title {
			@include home-souvenir-item-title();
		}
	}
}