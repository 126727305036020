// 
// Recruit
// 

.p-recruit-item {
	font-size: rem-calc(14);

	h2 {
		font-size: rem-calc(16);

		a {
			margin-right: .5em;
			$font-size-small: map-get( map-get( map-get( $header-styles, small ), 'h2'), 'font-size' );
			font-size: rem-calc( $font-size-small );

			@include breakpoint(medium) {
				$font-size-medium: map-get( map-get( map-get( $header-styles, medium ), 'h2'), 'font-size' );
				font-size: rem-calc( $font-size-medium );
			}
		}
	}
}