/**
 * Header
 *   - Layout
 *   - Logo
 *   - Primary Navigation (Medium up)
 *   - Secondary Navigation (Medium up)
 *   - Mobile Navigation (Small only)
 */


//
// Layout
//

.p-header {
	margin-bottom: 20px;

	@include breakpoint(medium) {
		margin-bottom: 0;
	}
}

.p-header__inner {

	@include breakpoint(medium) {
		display: flex;
		flex-direction: column-reverse;
	}

	// Logo & Primary Menu
	.p-header__row--primary {
		padding-top: 25px;
		padding-bottom: 25px;

		> .columns {
			display: flex;
			flex-direction: row;
			align-items: center;

			$logo-width: 220px;

			.p-header-logo {
				width: percentage( $logo-width / 1080px );

				@include breakpoint(small down) {
					width: auto;
				}
			}

			.p-header-nav-container {
				width: percentage( (1080px - $logo-width - 20px) / 1080px );
				margin-left: percentage( 20px / 1080px );
			}
		}

		.menu.expanded {
			table-layout: auto;
		}
	}

	// Secondary Menu
	.p-header-sub-nav-container {
		display: flex;
		justify-content: flex-end;
	}
}


//
// Logo
//
.p-header-logo {
	margin: 0;
	padding: 0;
	font-size: 100%;
	text-align: center;
}


//
// Primary Menu
//
.p-header-nav {
	.p-header-nav__item {
		border-left: 1px solid #c69c6d;

		&:last-child {
			border-right: 1px solid #c69c6d;
		}

		a {
			text-align: center;
			text-decoration: none;

			> span {
				display: block;
			}

			.ja {
				color: #46210c;
				font-size: rem-calc(14);
				margin-bottom: .25rem;
				@include noto-sans(500);
				transition: color .15s ease-out;
			}

			.en {
				color: #e47f12;
				font-size: rem-calc(10);
				@include noto-sans(400);
			}

			&:focus,
			&:hover {
				.ja {
					color: #e47f12;
				}
			}
		}
	}
}


// 
// Secondary Menu
// 
.p-header__row--secondary {
	background-color: #f8f7f2;
	border-bottom: 1px solid #c69c6d;
}

.p-header-sub-nav {
	a {
		color: #46210c;
		font-size: rem-calc(12);
		text-decoration: none;
		transition: color .15s ease-out;

		// arrow
		&:before {
			content: '';
			display: inline-block;
			margin-right: 5px;
			width: 0;
			height: 0;
			border-left: 5px solid #e47f12;
			border-top: 3px solid transparent;
			border-bottom: 3px solid transparent;
		}

		&:focus,
		&:hover {
			color: $primary-color;
		}
	}
}


//
// Mobile Menu
//

// Layout
@mixin header-mobile-nav() {
	@include clearfix();
	list-style: none;
	margin: 0;
	padding: 0 rem-calc(map-get($grid-column-gutter, small) / 2);
	width: 100%;

	> li {
		float: left;
		width: 32%; // 3columns
		margin: 0 0 2%;

		&:nth-child(3n+2) {
			margin-left: 2%;
			margin-right: 2%;
		}

		&:nth-last-of-type(1),
		&:nth-last-of-type(2),
		&:nth-last-of-type(3) {
			margin-bottom: 0;
		}
	}
}

// Button style
@mixin header-mobile-nav-button() {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	background-color: $color-orange;
	color: #FFFFFF;
	border-radius: 4px;
	font-size: rem-calc(12);
	line-height: 1.2;
	text-align: center;
	text-decoration: none;

	min-height: 68px;
	padding: 8px 5px;
}

.p-header-mobile-nav {
	@include header-mobile-nav();

	a {
		@include header-mobile-nav-button();

		// Icon
		&:after {
			content: '';
			display: block;
			margin-top: 5px;
			width: 40px;
			height: 18px;
			background-repeat: no-repeat;
			background-position: 50% 50%;
			background-size: auto 100%;
		}

		span {
			display: flex;
			align-items: center;
		}
	}

	// Category icons
	$icons: news, shop, shopnews, restaurant, souvenir, access;
	@each $name in $icons {
		.p-header-mobile-nav__item--#{$name} > a:after {
			background-image: url("../img/common/icon/icon-sp-header-#{$name}.svg");
		}
	}

	@include breakpoint(medium) {
		display: none;
	}
}