//
// Counter Ticket & Lottery
//

.p-counter-list {
	margin-left: 0;
}

.p-counter-item {
	list-style: none;
	font-size: rem-calc(14);
	margin-bottom: 1rem;

	.p-counter-item__image {
		margin-bottom: 1rem;
	}

	.p-counter-item__state {
		margin-bottom: .5rem;

		.label {
			min-width: 8em;
			text-align: center;
		}
	}

	.p-counter-item__title {
		font-weight: bold;
	}

	.p-counter-item__category {
		
	}

	.p-counter-item__date {
		
	}

	.p-counter-item__sales-period {
		
	}

	&--inline {
		padding-bottom: 1rem;
		border-bottom: 1px solid $color-brown-2;
	}

	&--pickup {
		margin-bottom: 2rem;

		.p-counter-item__image {
			float: left;
		}
	}
}


// 
// チケット販売状況カラー
// 
.p-counter-item__state {
	.label {
		&.p-counter-state--1 {
			background-color: #736451;
			color: #FFF;
		}
		&.p-counter-state--2 {
			background-color: #f08201;
			color: #FFF;
		}
		&.p-counter-state--3 {
			background-color: #739901;
			color: #FFF;
		}
		&.p-counter-state--4 {
			background-color: #cc0001;
			color: #FFF;
		}
		&.p-counter-state--5 {
			background-color: #cc0001;
			color: #FFF;
		}
		&.p-counter-state--6 {
			background-color: #177ab5;
			color: #FFF;
		}
		&.p-counter-state--7 {
			background-color: #ff6666;
			color: #FFF;
		}
	}
}