// 
// NotoSans JP
// http://fonts.googleapis.com/earlyaccess/notosansjp.css
// 

@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 400;
	src: url(../font/notosans/caoca-subset-notosans-regular.woff2) format('woff2'),
		url(../font/notosans/caoca-subset-notosans-regular.woff) format('woff'),
		url(../font/notosans/caoca-subset-notosans-regular.otf) format('opentype');
}
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 500;
	src: url(../font/notosans/caoca-subset-notosans-medium.woff2) format('woff2'),
		url(../font/notosans/caoca-subset-notosans-medium.woff) format('woff'),
		url(../font/notosans/caoca-subset-notosans-medium.otf) format('opentype');
}
@font-face {
	font-family: 'Noto Sans JP';
	font-style: normal;
	font-weight: 700;
	src: url(../font/notosans/caoca-subset-notosans-bold.woff2) format('woff2'),
		url(../font/notosans/caoca-subset-notosans-bold.woff) format('woff'),
		url(../font/notosans/caoca-subset-notosans-bold.otf) format('opentype');
}


/*
* Noto Sans JP (japanese) http://www.google.com/fonts/earlyaccess
 */

// @font-face {
// 	font-family: 'Noto Sans JP';
// 	font-style: normal;
// 	font-weight: 100;
// 	src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff2) format('woff2'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.woff) format('woff'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Thin.otf) format('opentype');
// }
// @font-face {
// 	font-family: 'Noto Sans JP';
// 	font-style: normal;
// 	font-weight: 300;
// 	src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff2) format('woff2'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.woff) format('woff'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Light.otf) format('opentype');
// }
// @font-face {
// 	font-family: 'Noto Sans JP';
// 	font-style: normal;
// 	font-weight: 400;
// 	src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff2) format('woff2'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.woff) format('woff'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Regular.otf) format('opentype');
// }
// @font-face {
// 	font-family: 'Noto Sans JP';
// 	font-style: normal;
// 	font-weight: 500;
// 	src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff2) format('woff2'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.woff) format('woff'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Medium.otf) format('opentype');
// }
// @font-face {
// 	font-family: 'Noto Sans JP';
// 	font-style: normal;
// 	font-weight: 700;
// 	src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff2) format('woff2'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.woff) format('woff'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Bold.otf) format('opentype');
// }
// @font-face {
// 	font-family: 'Noto Sans JP';
// 	font-style: normal;
// 	font-weight: 900;
// 	src: url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff2) format('woff2'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.woff) format('woff'),
// 		url(//fonts.gstatic.com/ea/notosansjp/v5/NotoSansJP-Black.otf) format('opentype');
// }