//
// Sidebar
//

// Header
.p-side-section__header {
	color: $color-brown;
	margin-top: 0;
	margin-bottom: 1rem;
	padding: .75em .5em;
	border-top: 3px solid $color-brown-2;
	border-bottom: 3px solid $color-brown-2;
	font-size: rem-calc(18);
	font-weight: bold;
}

// Navigation
.p-side-nav {
	margin: 0;
	padding: 0;
	list-style: none;

	&__item {
		margin: 0;

		a {
			position: relative;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			padding: .5em 1.5em .5em .5em;
			border-bottom: 1px solid #CCC;

			// Icon
			> img {
				margin-right: .5em;
				max-width: 24px;
			}

			&:after {
				content: '';
				display: block;
				position: absolute;
				right: .5em;
				top: 0;
				bottom: 0;
				margin-top: auto;
				margin-bottom: auto;
				// margin-left: auto;
				width: .5em;
				height: .5em;
				border-top: 1px solid #000;
				border-right: 1px solid #000;
				transform: rotate(45deg);
			}
		}
	}
}