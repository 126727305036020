// 
// Anchor Menu
// 

@mixin anchor-menu() {
	background-color: #F8F7F2;
	margin: 0 0 1rem;
	padding: 0;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;

	// mobile: vertical
	@include breakpoint(small down) {
		display: block;
	}

	> li {
		list-style: none;

		> a {
			display: block;
			padding: $menu-item-padding;
			line-height: $global-lineheight;
			@include anchor-menu-item-angle();
		}
	}
}

@mixin anchor-menu-item-angle() {
	&:before {
		content: '';
		display: inline-block;
		vertical-align: middle;
		margin-right: 0.5em;
		margin-bottom: 0.25em;
		width: 0.5em;
		height: 0.5em;
		border-top: 1px solid #46210c;
		border-right: 1px solid #46210c;
		transform: rotate(135deg);
	}
}


.c-anchor-menu {
	@include anchor-menu();
}