.p-floorguide-list__header {
	margin-top: 0;
}


//
// Index
//

// SVG size
$floorguide-top-svg: (
	large: (
		width:  994px,
		height: 558px
	),
	small: (
		width:  289px,
		height: 441px
	)
);


.p-svg-container {
	position: relative;
	height: 0;
	padding-top: 50%; // temp

	> svg {
		display: block;
		position: absolute;
		width: 100%;
		height: 100%;
		max-width: 100%;
		top: 0;
	}
}

.p-svg-container {
	&--top-building-large {
		$width:  map-get( map-get( $floorguide-top-svg, large ), width );
		$height: map-get( map-get( $floorguide-top-svg, large ), height );
		padding-top: percentage( $height / $width );
	}

	&--top-building-small {
		$width:  map-get( map-get( $floorguide-top-svg, small ), width );
		$height: map-get( map-get( $floorguide-top-svg, small ), height );
		padding-top: percentage( $height / $width );
	}
}


// 
// Floor
// 

.p-floorguide-row--primary {
	margin-bottom: 40px;
}

.p-floorguide-shop-list {
	> li {
		// margin-bottom: .5em;

		a {
			// display: block;
			display: flex !important;
			align-items: center;
			justify-content: flex-start;

			color: #a56638;
			@include clearfix;
			padding-top: 5px;
			padding-bottom: 5px;
			line-height: $global-lineheight;
		}
	}

	img {
		max-width: 24px;
		margin-right: .5em;
		float: left;
		display: block;
	}
	span {
		overflow: hidden;
		display: block;
	}
}

.p-floorguide-icon-list {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 0 0 20px;
	padding: 0;
	font-size: rem-calc(10);

	> li {
		width: 50%;
		margin-bottom: 5px;

		> img {
			// max-width: 24px;
			max-width: 16px;
			margin-right: .5em;
		}
	}

	&--shop {
		@include breakpoint(large) {
			> li {
				&:nth-child(odd) {
					width: 40%;
				}
				&:nth-child(even) {
					width: 60%;
				}
			}
		}
	}
}



// 
// Floor link
// 
.p-floorguide-floor-link {
	@include breakpoint(small down) {
		margin-top: 40px;
	}

	a {
		display: flex !important;
		align-items: center;
		// justify-content: space-between;
		justify-content: flex-start;
		color: #a56638;
		line-height: $global-lineheight;
		

		@include breakpoint(small down) {
			// border-bottom: 1px solid #CCC;
		}

		@include icon-arrow();

		// border-top: 1px solid lighten(#8C6239, 20%);


		// &:after {
		// 		content: '';
		// 		display: block;
		// 		// position: absolute;
		// 		// right: .5em;
		// 		// top: 0;
		// 		// bottom: 0;
		// 		// margin-top: auto;
		// 		// margin-bottom: auto;
		// 		// margin-left: auto;
		// 		width: .5em;
		// 		height: .5em;
		// 		border-top: 1px solid #000;
		// 		border-right: 1px solid #000;
		// 		transform: rotate(45deg);
		// 	}
	}

	li:last-child > a {
		// border-bottom: 1px solid lighten(#8C6239, 20%);
	}
}