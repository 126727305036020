//
// Typography
//

// Noto Sans
@mixin noto-sans( $weight: null ) {
	font-family: $font-family-noto;

	@if ( $weight == "normal" OR $weight == "regular" OR $weight == 400 ) {
		font-weight: 400;
	}

	@elseif ( $weight == "medium" OR $weight == 500 ) {
		font-weight: 500;
	}

	@elseif ( $weight == "bold" OR $weight == 700 ) {
		font-weight: 700;
	}
}

// Remove header style
@mixin clear-header-style( $level: null ) {

	@if $level == "h3" {
		border-left: 0;
		padding-left: 0;
	}

	@else {
		border: 0;
		padding: 0;
	}
}


// Text decoration
@mixin text-decoration( $decoration: underline, $decoration-hover: none ) {
	text-decoration: $decoration;

	&:focus,
	&:hover {
		text-decoration: $decoration-hover;
	}
}


//
// Images
//

// 100% width image
@mixin image-expanded() {
	width: 100%;
	height: auto;
}

// Image replacement
@mixin image-replacement() {
	text-indent: 100%;
	white-space: nowrap;
	overflow: hidden;
	word-wrap: normal;
}


//
// Layout
//

// clearfix
@mixin clearfix() {
	&:after {
		content: '';
		clear: both;
		display: block;
	}
}


// 
// CSS3
// 

// transition
@mixin transition( $property: all ) {
	transition: $property .25s ease-out;
}