//
// Page title
//
.p-page-header__title {
	background-color: $primary-color;
	color: #FFFFFF;
	font-weight: normal;
	border-radius: 4px;
	padding: .5em .75em;
	margin-bottom: rem-calc(40);
}


//
// Headings
//
h2,
h3,
h4,
h5,
h6 {
	margin-top: 1.5em;
	margin-bottom: 1em;
	font-weight: bold;
}

h2 {
	color: $color-brown;
	padding-bottom: .25em;
	padding-left: .5em;
	border-bottom: 3px solid $color-orange-2;
}

h3 {
	color: $color-brown;
	padding-left: .5em;
	border-left: 6px solid $color-brown;
}

h4 {
	color: $primary-color;
}


//
// Strong
//

strong em {
	font-weight: bold;
	font-style: normal;
	color: $primary-color;
}

strong.important {
	&,
	& em {
		color: #CC0000;
	}
}


//
// Address
//
address {
	font-style: normal;
}


//
// Table
//
table {
	max-width: 100%;
}