/**
 * Footer
 *   - Layout
 *   - Banner: Access & Counter
 *   - Navigation
 *   - Address
 *   - Group banner
 *   - Copyright
 */


.p-footer {
	margin-top: 80px;
	padding-top: 50px;
	background-color: $color-beige;
	font-size: rem-calc(14);

	@include breakpoint(small down) {
		margin-top: 0;
		padding-top: 20px;
	}
}


// 
// Layout
// 
.p-footer__inner--address {
	border-top: 1px solid $color-brown-2;
	border-bottom: 1px solid $color-brown-2;
	padding-top: 1rem;
	padding-bottom: 1rem;

	@include breakpoint(medium) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
}


//
// Banner: Access & Counter
//
@mixin footer-banner-style() {
	list-style: none;
	margin: 0;
	padding: 0;

	> li {
		a {
			display: block;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
}

@mixin footer-banner-layout() {
	> li {
		margin-bottom: 4%;
	}

	@include breakpoint(medium) {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		> li {
			width: 48%;

			&:nth-child(odd) {
				margin-right: 4%;
			}
		}
	}
}

.p-footer-banner {
	@include footer-banner-style();
	@include footer-banner-layout();
}


//
// Footer Navigation
//
@mixin footer-menu-button-style() {
	display: block;
	background-color: #FFFFFF;
	color: #A56638;
	padding: 0.75em 1em;
	border-radius: $global-radius;
	text-decoration: none;
}

@mixin footer-menu-icon() {
	> a:before {
		content: '';
		display: inline-block;
		vertical-align: text-bottom;
		width: 1em;
		height: 1em;
		background-image: url("../img/common/icon/icon-footer-recruit.svg");
		background-repeat: no-repeat;
		background-size: 1em auto;
		margin-right: .5em;
	}

	$icons: information, sitemap, recruit, mail, external;
	@each $name in $icons {
		&--#{$name} > a:before {
			background-image: url("../img/common/icon/icon-footer-#{$name}.svg");
		}
	}
}

.p-footer-nav {
	list-style: none;
	margin: 0 0 1em;
	padding: 0;
	display: flex;
	flex-direction: column;

	@include breakpoint(medium) {
		flex-wrap: wrap;
		flex-direction: row;
	}
}

.p-footer-nav__item {
	margin-bottom: rem-calc(10);
	@include footer-menu-icon();

	@include breakpoint(small down) {
		width: 100%;
	}

	@include breakpoint(medium) {
		font-size: rem-calc(14);

		&--recruit,
		&--mail {
			min-width: 13em;
		}
		&--information,
		&--sitemap {
			min-width: 9em;
		}
		&--external {
		}
	}

	a {
		// Mobile: button style
		@include breakpoint(small down) {
			@include footer-menu-button-style();
		}

		@include breakpoint(medium) {
			color: #603813;
			@include text-decoration(underline, none);
		}
	}
}


// 
// Address
// 
.p-org-info {
	margin-bottom: 2em;

	@include breakpoint(medium) {
		margin-bottom: 0;
	}

	p {
		margin-bottom: 0;
	}

	.p-org-info__postal-address {
		margin-right: 1em;
	}

	// Telephone
	.p-org-info__tel {
		> span {
			@include breakpoint(small down) {
				display: none;
			}
		}

		> .p-org-info__tel-link {

			// Mobile: button style
			@include breakpoint(small down) {
				@include button();
				@include button-expand();
				text-decoration: none;
				font-size: map-get($button-sizes, large);
				margin-top: 1rem;

				// icon
				&:before {
					content: '';
					display: inline-block;
					vertical-align: middle;
					width: 1em;
					height: 1em;
					background-image: url("../img/common/icon/icon-tel-white.svg");
					background-repeat: no-repeat;
					background-size: 1em auto;
					margin-right: .25em;
				}
			}

			@include breakpoint(medium) {
				@include text-decoration(none, underline);
				color: $body-font-color;
			}
		}
	}
}


//
// Address & Group banner
//
@mixin footer-group-banner-layout() {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin: 0;
	padding: 0;

	> li {
		margin: .5em 1em .5em 0;

		&:last-child {
			margin-right: 0;
		}
	}
}

.p-footer-group-banner {
	@include footer-group-banner-layout();

	> li {
		list-style: none;
		padding: 0;

		img {
			max-height: 33px;
			width: auto;
		}
	}
}


//
// Copyright
//
.p-copyright {
	text-align: center;
	margin: 0;
	padding: 1rem;

	small {
		font-size: rem(14);

		@include breakpoint(small down) {
			font-size: rem(10);
		}
	}
}