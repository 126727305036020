// 
// Form
// 

form {
	label {
		.attention {
			@include label();
			background-color: #C00;
			color: #FFF;
		}
	}

	.help-text {
		font-style: normal;
	}

	td {
		> :last-child {
			margin-bottom: 0;

			> :last-child {
				margin-bottom: 0;
			}
		}
	}
}


// 
// Contact page
// 

.contact {
	form {
		table {
			th {
				text-align: left;

				@include breakpoint(large) {
					width: 40%;
					text-align: right;
				}

				// 必須
				.attention {
					margin-left: .5em;
				}
			}

			td {
				// Error
				.attention {
					color: #CC0000;
					font-weight: bold;
				}
			}
		}
	}
}


// 
// Mail magazine form
// http://www.caoca.net/lists/index.php?p=subscribe&id=1
// 

form {
	> dl {
		@include breakpoint(large) {
			display: flex;
			align-items: stretch;
			justify-content: flex-start;
			flex-wrap: wrap;
		}

		> dt,
		> dd {
			display: block;
			padding: .5rem .625rem .625rem;
			margin: 0;
			background-color: #fcfcfc;
		}

		> dt {
			font-weight: normal;

			@include breakpoint(large) {
				width: 40%;
				text-align: right;
				display: flex;
				align-items: center;
				justify-content: flex-end;
			}
		}

		> dd {
			@include breakpoint(large) {
				width: 60%;
			}

			> :last-child {
				margin-bottom: 0;
			}
		}
	}
}