.p-hero {
	margin-bottom: 60px;
	@include breakpoint(small down) {
		margin-bottom: 20px;
	}
}

.p-hero-main {
	margin-bottom: 20px;

	&__item {

		> * {
			display: block;
			margin-left: rem-calc(map-get($grid-column-gutter, small) / 2);
			margin-right: rem-calc(map-get($grid-column-gutter, small) / 2);

			@include breakpoint(medium) {
				margin-left: 15px;
				margin-right: 15px;
			}
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	// Slick
	.slick-slide {
		// opacity: .7;

		&.slick-active {
			// opacity: 1;
		}

		&:not(.slick-active) {
			// opacity: .7;
		}
	}

	.slick-dots {
		display: flex;
		justify-content: center;
		align-items: center;
		list-style: none;
		margin: 20px 0;
		padding: 0;

		> li {
			margin: 0 4px;
			display: flex;
			align-items: center;

			button {
				@include image-replacement();
				border-radius: 50%;
				background-color: #999999;
				width: rem-calc(8);
				height: rem-calc(8);
				cursor: pointer;
			}

			&.slick-active {
				button {
					background-color: $color-orange;
				}
			}
		}
	}
}

//
// Slick
//
.slick-arrow {
	@include image-replacement();
	cursor: pointer;
	outline: 0;
	display: block;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	background-color: rgba(#46210C, 0.7);
	position: absolute;
	z-index: 2;
	top: 50%;
	margin-top: -30px;

	&:after {
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 15px;
		height: 15px;
		border-top: 3px solid #FFF;
		border-left: 3px solid #FFF;
	}

	&.slick-prev {
		left: 10%;
		margin-left: -15px;

		&:after {
			transform: translate(-50%, -50%) rotate(-45deg);
			margin-left: 3px;
		}
	}

	&.slick-next {
		right: 10%;
		margin-right: -15px;

		&:after {
			transform: translate(-50%, -50%) rotate(135deg);
			margin-left: -3px;
		}
	}
}


.p-hero-thumb {
	display: flex;
	justify-content: space-between;

	@include breakpoint(small down) {
		display: none;
	}

	&__item {
		width: 24%;

		img {
			// width: 100%;
			// height: 80px;
			background-color: #f8f8f8;
		}
	}
}