//
// イベント・キャンペーン
// Event & Campaign
//

$news-image-animation: true;


@mixin news-item() {
	list-style: none;
	margin-top: 0;
	margin-bottom: 2em;
	padding-top: 0;
	padding-bottom: 0;
}

@mixin news-item-link() {
	text-decoration: none;
	color: #a56638;
}

@mixin news-item-meta() {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	margin-bottom: .5em;
}

@mixin news-item-tag() {
	min-width: 6em;
	text-align: center;
	margin-right: .5em;

	&.p-label--topico {
		background-color: #996600;
	}
	&.p-label--als {
		background-color: #ff9900;
	}
}

@mixin news-item-date() {
	color: $body-font-color;
	font-size: rem-calc(14);
	white-space: nowrap;
}

@mixin news-item-image() {
	margin-bottom: 1em;
	position: relative;
}

@mixin news-item-image-background() {
	display: block;
	height: 0;
	padding-top: 100%;
	position: relative;
	background-size: cover;
}

@mixin news-item-shop-name() {
	margin-bottom: .5em;
	color: $body-font-color;
	font-weight: bold;

	> img {
		width: 24px;
		margin-right: .5em;
	}
}

@mixin news-item-title() {
	text-decoration: underline;
}


// "More" animation
@mixin news-item-image-more() {
	@if $news-image-animation == true {
		content: 'More';
		display: inline-flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		margin: auto;
		padding: 1em;
		width: 7em;
		height: 2em * $global-lineheight;
		text-align: center;
		color: #FFF;
		border: 3px solid #FFF;
		z-index: 1;
		transition: all .35s ease-in-out;
		opacity: 0;
	}
}

@mixin news-item-image-more-bg() {
	@if $news-image-animation == true {
		content: '';
		opacity: 0;
		width: 0;
		height: 0;
		transition: all .25s ease-in-out;
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		background-color: rgba($color-orange, 0.5);
	}
}


.p-news-item {
	@include news-item();

	> a {
		@include news-item-link();

		&:focus,
		&:hover {
			.p-news-item__title {
				text-decoration: none;
			}
		}
	}

	&__meta {
		@include news-item-meta();
	}

	.label {
		@include news-item-tag();
	}

	time {
		@include news-item-date();
	}

	&__image {
		@include news-item-image();

		&:before {
			@include news-item-image-more();
		}

		&:after {
			@include news-item-image-more-bg();
		}

		span {
			@include news-item-image-background();
		}
	}

	> a {
		&:focus,
		&:hover {
			.p-news-item__image {
				&:before {
					opacity: 1;
				}

				&:after {
					width: 100%;
					height: 100%;
					opacity: 1;
				}
			}
		}
	}

	&__shop-name {
		@include news-item-shop-name();
	}

	&__title {
		@include news-item-title();
	}
}
