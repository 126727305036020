//
// Colors
//

// Beige
$color-beige:      #f8f7f2;
$color-dark-beige-color:  #c69c6d;

// Brown
$color-brown:   #46210c;
$color-brown-2: #8c6239; // border

// Orange
$color-orange:   #E47F12; // 濃いめ
$color-orange-2: #ff7b00; // ちょっと明るい
$color-orange-3: #FF9900; // 明るい


//
// Typography
//

$font-family-noto: 'Noto Sans JP', sans-serif;
