//
// Anchor
//

.p-category-anchor {

}


//
// Restaurant List
//


//
// Restaurant Item
//

@mixin restaurant-item-layout() {
	@include breakpoint(small down) {
		border-bottom: 1px dotted #cccccc;
	}

	.p-restaurant-item__image,
	.p-restaurant-item__body {
		@include breakpoint(small down) {
			display: table-cell;
			vertical-align: top;
		}
	}

	.p-restaurant-item__image {
		@include breakpoint(small down) {
			width: 30%;
			padding-right: 1rem;
		}
	}

	.p-restaurant-item__body {
		> :first-child {
			margin-top: 0;
		}
	}
}


@mixin restaurant-item-image() {
	.p-restaurant-item__image {
		margin-bottom: 1rem;

		img {
			width: 100%;
			height: auto;
		}
	}
}

@mixin restaurant-item-category() {
	.p-restaurant-item__category {
		background-color: $color-beige;
		padding: .5em .75em;
		font-size: rem-calc(14);
	}
}

@mixin restaurant-item-info() {
	dl {
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;
		font-size: rem-calc(14);

		dt,
		dd {
			margin: 0;
		}

		dt {
			width: 40%;
			padding-right: .5em;
		}

		dd {
			width: 60%;
		}
	}
}


.p-restaurant-item {
	list-style: none;
	margin-bottom: 1.5em;
	@include restaurant-item-image();
	@include restaurant-item-info();
	@include restaurant-item-category();
	@include restaurant-item-layout();

	&__image {
	}

	&__body {
	}

	&__title {
		@include clear-header-style("h3");
		font-size: rem-calc(18);
		margin-bottom: .5em;

		a {
			display: block;
		}
	}
}