//
// ショップ一覧ページ
// Shop
//

// Header（見出し）
.p-shop-list__header {
	> img {
		margin-right: .5em;
		max-width: 24px;
	}
}


// 
// ショップニュース（店舗ページ）
// Shop News
// 
.p-shop-news-image__main {
	margin-bottom: 0.5rem;
}

.p-shop-news-image__sub {
	list-style: none;
	margin: 0 0 1rem;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;

	> li {
		width: 32%;
		margin-bottom: 2%;

		&:nth-child(3n+2) {
			width: 36%;
			padding-left: 2%;
			padding-right: 2%;
		}
	}
}


//
// 関連ショップ
// Other Shop
//

.p-other-shop-item {
	list-style: none;
	margin-bottom: 1rem;

	> a {
		color: $body-font-color;

		&:focus,
		&:hover {

		}
	}

	// image
	.p-other-shop-item__image {
		margin-bottom: 1rem;

		> div {
			width: 100%;
			height: 0;
			padding-top: 100%;
			display: block;
			background-size: cover;
			background-position: 50% 0;
			background-color: $color-beige;

			// image: inline
		}
	}

	// text
	.p-other-shop-item__body {
		margin-bottom: 1rem;

		h3 {
			@include clear-header-style("h3");
			margin-top: 0;
			font-size: rem-calc(16);
		}

		p {
			font-size: rem-calc(14);
		}
	}
}


//
// ショップニュース一覧
// Shop News
//

.p-shop-news-list {
	.p-shop-news-article {
		margin-bottom: 1rem;

		> a {
			display: block;
			color: $body-font-color;

			&:focus,
			&:hover {
				color: $primary-color;
			}
		}

		.p-shop-news-item__time {
			font-size: rem-calc(14);
		}

		h3 {
			@include clear-header-style("h3");
			margin-top: 0;
		}
	}

	.p-shop-news-item__image {
		position: relative;
		width: 100%;
		height: 0;
		padding-top: 100%;
		margin: 0;

		> span {
			background-color: #FFF;
			background-position: top center;
			background-repeat: no-repeat;
			background-size: cover;
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}
	}
}
