// 
// Common
// 

@mixin souvenir-contact() {
	margin-top: 1em;
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	> :not(:last-child) {
		margin-right: 1em;
	}
}

.p-souvenir-contact {
	@include souvenir-contact();

	> .p-souvenir-contact__logo {
		img {
			max-height: 3em;
			width: auto;
		}
	}
}


// 
// Sweets
// 

.p-sweets-item {
	margin-bottom: 2em;

	&__image {
		margin-bottom: 1rem;

		img {
			width: 100%;
			height: auto;
		}
	}

	&__body {
		h3 {
			@include clear-header-style("h3");
			margin-top: 0;
			margin-bottom: 1rem;
			font-size: rem-calc(16);
		}

		.p-sweets-item__info {
			background-color: $color-beige;
			padding: .25em .5em;
		}
	}
}


// 
// Sweets selection
// 

.p-sweets-sel-brand--new {
	h2 {
		&:before {
			content: 'NEW!';
			display: inline-block;
			width: 4em;
			height: 4em;
			line-height: 4em;
			font-size: rem-calc(16);
			background-color: #CC0000;
			color: #FFF;
			border-radius: 50%;
			text-align: center;
			margin-right: 1em;
		}
	}
}

.p-sweets-sel-brand__image {
	img {
		width: 100%;
		height: auto;
	}
}

.p-sweets-sel-item__price {
	background-color: $color-beige;
	padding: .5em .75em;
	margin-bottom: 1em;

	> :last-child {
		margin-bottom: 0;
	}
}

.p-sweets-sel-contact {
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	> :not(:last-child) {
		margin-right: 1em;
	}
}



// 
// Dish
// 

.p-souvenir-dish-image {
	img {
		width: 100%;
		height: auto;
	}
}